import { CoverRoverLogo } from '@/components/CoverRoverLogo/CoverRoverLogo';

export function Error404(): JSX.Element {
  return (
    <div className="h-[62vh] flex flex-col items-center justify-center">
      <CoverRoverLogo width={208} height={44} />
      <span className="mt-8 font-playfair text-34px">404 PAGE NOT FOUND</span>
    </div>
  );
}

export default Error404;
